import {
	FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE,
	GET_USER_ROLES, GET_UNIVERSITIES, GET_PROFESSIONS, GET_GENDER, GET_STATUS, GET_COUNTRY, GET_ACTIVITY, GET_COURSE, GET_COURSES, GET_SCHOOLS, GET_STUDENT_GRADES, GET_ACTIVITIES,
	GET_PHASE_TIME, GET_GRADUATION_YEAR, SET_COPY_FLAG, GET_RECOMMENDED_STUDENTS, GET_RECOMMENDED_STUDENTS_STATUS, GET_RECOMMENDED_STUDENTS_ADVISOR, GET_PENDING_REQUESTS,
	GET_DETAILED_USER_PROFILE, SET_DETAILED_USER_PROFILE_STATUS, GET_CONNECTED_PARENTS, GET_CONNECTED_STUDENTS, GET_CONNECTED_ADVISORS, SET_CONNECTED_ADVISORS_STATUS,
	SET_CONNECTED_PARENT_STATUS, SET_CONNECTED_STUDENTS_STATUS, GET_NOTIFICATION, VIEW_NOTIFICATION, BOOK_SESSION, LIST_SCHEDULED_EVENTS, GET_CONNECTED_ADVISORS_SWITCH,
	GET_CONNECTED_FRIEND_SWITCH, SET_CONNECTED_CHILD_STATUS, RESCHEDULE_SESSION, RESCHEDULE_SESSION_ERROR, BOOK_SESSION_ERROR,
	GET_CONNECTED_PARENT_SWITCH, LIST_SCHEDULED_EVENTS_SWITCH, STUDENT_PLANS_INFO, STUDENT_PLANS_INFO_STATUS, PARENT_PLANS_DETAIL, PARENT_PLANS_INFO_STATUS, GET_SENT_REQUESTS,
	BOOK_PAID_SESSION_ERROR, BOOK_PAID_SESSION, FREE_SESSION_REMAINING, SCHEDULED_FREESESSION_DETAILS, CANCEL_SCHEDULE_SESSION, CANCEL_SCHEDULE_SESSION_ERROR, ERROR_CANCEL_REQUEST,
	ADVISOR_FEADBACK_SUBMIT, CANCEL_REQUEST, STUDENT_PLANS_INFO_RESET, ADD_ADVISOR_STATUS_ERROR, ADD_ADVISOR_STATUS, LOAD_ADVISOR_REVIEW, RESET_PENDING_REQUESTS,
	GET_SENT_REQUESTS_STATUS, GET_PENDING_REQUESTS_STATUS, SCHEDULED_SESSION_DETAILS, SCHEDULED_SESSION_DETAILS_STATUS, RESCHEDULE_PAID_SESSION, LIST_SCHEDULED_EVENTS_STATUS,
	HIDE_CONNECTION_REQUEST, GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS, ADD_STUDENT_STATUS_ERROR, ADD_STUDENT_STATUS, REMOVE_STUDENT_STATUS_ERROR, REMOVE_STUDENT_STATUS, GET_EXPLORE_ADVISOR_STATUS,
	ADVISOR_PROFILE_SAVE_ERROR, LOAD_SAVED_USER_PROFILE_STATUS, LOAD_SAVED_USER_PROFILE, SAVE_USER_PROFILE, GET_EXPLORE_STUDENT, GET_ADVISOR_AVAILABILITY, SEND_RESPOND_TO_PARENT_REQUEST,
	GENERIC_SEARCH_ADVISOR, GENERIC_SEARCH_STUDENT, GENERIC_SEARCH_STUDENT_STATUS, UNSUBSCRIBE_ADVISOR, GET_EXPLORE_STUDENT_STATUS, EXPLORE_ADVISOR_PAGINATION, EXPLORE_ADVISOR_PAGINATION_STATUS, LOAD_SPECIFIC_EXPLORE_ADVISOR, LOAD_SPECIFIC_EXPLORE_ADVISOR_STATUS,
	GET_ZIPCODES, SCHEDULED_FREESESSION_DETAILS_STATUS, GET_USER_COUNT, ADVISOR_FEADBACK_EDIT, ADVISOR_FEADBACK_DELETE
	, GET_NETWORK_USER_COUNT_STATUS, GET_NETWORK_USER_COUNT, SET_ARCHIVE_PLAN_ERROR, SET_ARCHIVE_PLAN, TOTAL_ADVISOR_REVIEW, LOAD_ADVISOR_REVIEW_STATUS, RESET_REVIEW,
	SET_PHASE_TIME_STATUS, SET_ARCHIVE_COURSE, SET_ARCHIVE_COURSE_ERROR, SET_UNARCHIVE_COURSE_ERROR, SET_UNARCHIVE_COURSE, GET_EXPLORE_ADVISOR_COUNT,
	SET_UNARCHIVE_ACTIVITY_ERROR, SET_UNARCHIVE_ACTIVITY, SET_ARCHIVE_ACTIVITY_ERROR, SET_ARCHIVE_ACTIVITY, GET_UPDATES_VIEWED_COUNT, USER_INVITE_ADVISOR, USER_INVITE_STUDENT, CURRENT_COURSES_DASHBOARD, CURRENT_ACTIVITY_DASHBOARD, USER_INVITE_STUDENT_ERROR,GET_ALL_DATA_PROFILE
} from "../constants/CommonTypes";
import {
	CUSTOM_ALERT_CLOSE, CLEAR_MESSAGE, CLEAR_ERROR_MESSAGE, GET_ADVISOR_RECOMMENDED, GET_CONNECTED_FRIENDS, SET_CONNECTED_FRIEND_STATUS, GET_ADVISOR_RECOMMENDED_STATUS,
	USER_CONNECT_STATUS, CUSTOM_ALERT_SHOW, USER_CONNECT_ERROR_STATUS, SEND_RESPOND_TO_REQUEST, SET_UNARCHIVE_PLAN, GET_ADVISOR_SUGGESTED_STATUS, GET_ADVISOR_SUGGESTED, INVITATION_REVOKE_ERROR, INVITATION_REVOKE
} from "../constants/CommonTypes";
import axios from 'axios'
import { GET_EXPLORE_STUDENT_BY_SECTION } from "../constants/ExploreTypes";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchStart = () => {
	return {
		type: FETCH_START
	}
};

export const fetchSuccess = () => {
	return {
		type: FETCH_SUCCESS
	}
};

export const fetchError = (error) => {
	return {
		type: FETCH_ERROR,
		payload: error
	}
};

export const setCopyInviteFlag = (status) => {
	return {
		type: SET_COPY_FLAG,
		payload: status
	}
};

export const showMessage = (message) => {
	return {
		type: SHOW_MESSAGE,
		payload: message
	}
};

export const hideMessage = () => {
	return {
		type: HIDE_MESSAGE
	}
};

export const clearMessage = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_MESSAGE })
		dispatch({ type: CUSTOM_ALERT_CLOSE })
	}
};

export const clearErrorList = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_ERROR_MESSAGE })
	}
};

export const getUserRoles = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/user-service/v1/master/usertypes').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_USER_ROLES, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const getProfessions = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/config-service/v1/master/professions').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_PROFESSIONS, payload: data.recordInfo });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const getUniversities = requestParams => {
	return (dispatch) => {
		axios.post(BASE_URL + '/config-service/v1/master/universities', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_UNIVERSITIES, payload: data.data });
			}
			else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				console.log(error?.response, "error?.response");
				const { message } = error?.response;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
				dispatch({ type: GET_UNIVERSITIES, payload: "" });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: GET_UNIVERSITIES, payload: "" });
			}
			console.log("Error****:", error.message);
		});
	}
};
export const getSchools = requestParams => {
	return (dispatch) => {
		axios.post(BASE_URL + '/config-service/v1/master/schools', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_SCHOOLS, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
				dispatch({ type: GET_SCHOOLS, payload: "" });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: GET_SCHOOLS, payload: "" });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const getGender = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/user-service/v1/master/gender').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_GENDER, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const getStatus = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/config-service/v1/master/studentstatus').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_STATUS, payload: data.recordInfo });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const getCountry = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/config-service/v1/master/countries').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_COUNTRY, payload: data.recordInfo });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

// --> Activity Type
export const getActivities = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/config-service/v1/master/activitytypes').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });

				dispatch({ type: GET_ACTIVITY, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
}

// --> Course Type 
export const getCourses = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/config-service/v1/master/coursetypes').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });

				dispatch({ type: GET_COURSE, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		})

	}
}

// --> Course Name
export const getCourse = () => {
	return (dispatch) => {
		axios.post(BASE_URL + '/config-service/v1/master/courses').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_COURSES, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

// --> Activity Name
export const getActivity = () => {
	return (dispatch) => {
		axios.post(BASE_URL + '/config-service/v1/master/activities').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_ACTIVITIES, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};


//advisor recommendation
export const getAdvisorRecommended = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/advisorsforplan', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_ADVISOR_RECOMMENDED_STATUS, payload: true });
				dispatch({ type: GET_ADVISOR_RECOMMENDED, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const getAdvisorRecommendedstatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_ADVISOR_RECOMMENDED_STATUS, payload: status });
	}
}

export const getStudentGrades = () => {
	return (dispatch) => {
		axios.post(BASE_URL + '/config-service/v1/master/studentgrades').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_STUDENT_GRADES, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const getPhasetime = (requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/student-plans/v1/phases/phaseduration/' + requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_PHASE_TIME, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

export const setPhaseTimeStatus = (status) => {
	return (dispatch) => {
		dispatch({ type: SET_PHASE_TIME_STATUS, payload: status });
	}
}

export const getGraduationyear = () => {
	return (dispatch) => {
		axios.get(BASE_URL + '/config-service/v1/master/graduationyear').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_GRADUATION_YEAR, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		});
	}
};

// Student Recomendation
export const getRecommendedStudents = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/bygoals', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_RECOMMENDED_STUDENTS, payload: data.data });
				dispatch({ type: GET_RECOMMENDED_STUDENTS_STATUS, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const getStudentRecommendedstatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_RECOMMENDED_STUDENTS_STATUS, payload: status });
	}
}

//
export const getRecommeStudentAdvisor = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/foradvisors', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_RECOMMENDED_STUDENTS_ADVISOR, payload: data.data });
				dispatch({ type: GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const recommentedStudentStatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS, payload: status });
	}
}
export const resetRecommendedStudentData = () => {
	return (dispatch) => {
		dispatch({ type: GET_RECOMMENDED_STUDENTS_ADVISOR, payload: [] });
	}
}

/*   -- NETWORK SECTION --    */

export const userConnect = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/network/requests/new', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_CONNECT_STATUS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000);
			}
		} catch (error) {
			dispatch({ type: USER_CONNECT_ERROR_STATUS, payload: true })
			if (error.response) {
				if (error.response.data.headers?.message !== "") {
					console.log("else if");
					dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers?.message } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
				} else if (error.response.data.errorlist?.email !== "") {
					console.log("if");
					dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.errorlist?.email } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist?.email, success: false } });
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
				} else {
					console.log("else");
					const { headers: { message, statusCode }, errorlist } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email.split(" ").slice(0, -4).join(" "), success: false } });
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
				}
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			}
			console.log("Error****:", error.message);
		}
	};
};

export const userConnectStatus = status => {
	return (dispatch) => {
		dispatch({ type: USER_CONNECT_STATUS, payload: status });
	}
}

export const userConnectErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: USER_CONNECT_ERROR_STATUS, payload: status });
	}
}

export const getPendingRequests = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/requests/pending');
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_PENDING_REQUESTS, payload: data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	};
};
export const setPendingRequestsStatus = (status) => {
	return (dispatch) => {
		dispatch({ type: GET_PENDING_REQUESTS_STATUS, payload: status });
	}
}
export const resetPendingRequestData = () => {
	return (dispatch) => {
		dispatch({ type: RESET_PENDING_REQUESTS, payload: [] });
	}
}
export const getPendingRequestsParent = (id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/parents/requests/pending/' + id);
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				console.log(data, "bchdbhcb")
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_PENDING_REQUESTS, payload: data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	};
};

export const setPendingRequestStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTED_PARENT_STATUS, payload: status });
	}
}

export const getSentRequests = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/requests/sent');
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_SENT_REQUESTS, payload: data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	};
};
export const setSentRequestsStatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_SENT_REQUESTS_STATUS, payload: status });
	}
}

export const getSentRequestsParent = (id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/parents/requests/sent/' + id);
			const { headers: { statusCode, message } } = data;
			console.log("getSentRequestsParent", data);
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_SENT_REQUESTS, payload: data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	};
};

export const getConnectedParents = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/connectedusers/parents');
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_PARENTS, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	}
}
export const setConnectedParentStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTED_PARENT_STATUS, payload: status });
	}
}

export const getConnectedFriends = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/connectedusers');
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_FRIENDS, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	}
}

export const setConnectedFriendStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTED_FRIEND_STATUS, payload: status });
	}
}

export const getConnectedStudents = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/connectedusers/students');
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_STUDENTS, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				console.log("error", error.response);
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}
	}
}

export const ResetConnectedStudent = () => {
	return (dispatch) => {
		dispatch({ type: GET_CONNECTED_STUDENTS, payload: [] });
	}
}

export const setConnectedStudentStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTED_STUDENTS_STATUS, payload: status });
	}
}

export const getConnectedAdvisors = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/connectedusers/advisors');
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_ADVISORS, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}

	}
}

export const setConnectedAdvisorStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTED_ADVISORS_STATUS, payload: status });
	}
}

export const getConnectedAdvisorsParents = (id) => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/parents/getconnectedadvisors/' + id);
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_ADVISORS, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}

	}
}


export const getConnectedFriendSwitch = (id) => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/parents/getconnectedstudents/' + id);
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_FRIEND_SWITCH, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}

	}
}

export const getConnectedParentSwitch = (id) => {
	return async (dispatch) => {
		try {
			dispatch({ type: FETCH_START });
			const { data } = await axios.get(BASE_URL + '/user-service/v1/network/parents/getconnectedparents/' + id);
			const { headers: { statusCode, message } } = data;

			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_CONNECTED_PARENT_SWITCH, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message } } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
		}

	}
}

export const setChildSwichStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_CONNECTED_CHILD_STATUS, payload: status });
	}
}

// Detailed advisor view profile 
export const getDetailedUserProfile = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/network/viewprofile', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_DETAILED_USER_PROFILE, payload: { data: data?.recordInfo, status: true } });
				dispatch({ type: GET_ALL_DATA_PROFILE, payload: true} );
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const resetDetailedUserData = () => {
	return (dispatch) => {
		dispatch({ type: SET_DETAILED_USER_PROFILE_STATUS, payload: [] });
	}
}

export const resetDetailedUserProfileStatus = () => {
	return (dispatch) => {
		dispatch({ type: GET_DETAILED_USER_PROFILE, payload: {} });
	}
}
export const settDetailedUserProfileStatus = status => {
	return (dispatch) => {
		dispatch({ type: SET_DETAILED_USER_PROFILE_STATUS, payload: status });
	}
}
export const allDataLoadedStatus = (status) => {
	console.log(status,"status");
	
	return (dispatch) => {
		dispatch({ type: GET_ALL_DATA_PROFILE, payload: status });
	}
}



// NetworkFriendRequest
export const sendRespondToRequest = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/network/requests/respond', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SEND_RESPOND_TO_REQUEST, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			}
		} catch (error) {
			// dispatch({ type: USER_CONNECT_ERROR_STATUS, payload: true })
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		}
	};
};

export const setSendRespondToRequestStatus = status => {
	return (dispatch) => {
		dispatch({ type: SEND_RESPOND_TO_REQUEST, payload: status });
	}
}

export const getnotification = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/notification-service/v1/notifications/mynotifications', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_NOTIFICATION, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const viewednotification = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/notification-service/v1/notifications/updatestatus', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: VIEW_NOTIFICATION, payload: true });
				let payload = { "pageNo": 1, "limit": 50 }
				dispatch(getnotification(payload));
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const viewedNotificationStatus = status => {
	return (dispatch) => {
		dispatch({ type: VIEW_NOTIFICATION, payload: status });
	}
}
export const bookSession = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/events-service/v1/events/bookFreeSession', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 4000)
				dispatch({ type: BOOK_SESSION, payload: true })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: BOOK_SESSION_ERROR, payload: true });
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}

export const bookPaidSession = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/events-service/v1/events/bookpaidsession', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: BOOK_PAID_SESSION, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: BOOK_PAID_SESSION_ERROR, payload: true });
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		});
	}
}

export const bookPaidSessionStatus = status => {
	return (dispatch) => {
		dispatch({ type: BOOK_PAID_SESSION, payload: status });
	}
}

export const bookPaidSessionErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: BOOK_PAID_SESSION_ERROR, payload: status });
	}
}

export const bookSessionStatus = status => {
	return (dispatch) => {
		dispatch({ type: BOOK_SESSION, payload: status });
	}
}
export const bookSessionErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: BOOK_SESSION_ERROR, payload: status });
	}
}
export const getStudentPlanDetails = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/network/plans/load/student', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: STUDENT_PLANS_INFO, payload: { data: data.data, status: true } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: STUDENT_PLANS_INFO_STATUS, payload: true });
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response?.data?.headers.message } });
			}
		});
	}
}
export const getStudentPlanStatus = status => {
	return (dispatch) => {
		dispatch({ type: STUDENT_PLANS_INFO_STATUS, payload: status });
	}
}
export const resetStudentPlansData = data => {
	return (dispatch) => {
		dispatch({ type: STUDENT_PLANS_INFO_RESET, payload: data });
	}
}

// Event Section
export const listScheduledEvents = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/events-service/v1/events').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LIST_SCHEDULED_EVENTS, payload: { success: true, data: data?.data } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const setListScheduledEventStatus = status => {
	return (dispatch) => {
		dispatch({ type: LIST_SCHEDULED_EVENTS_STATUS, payload: status });
	}
};
export const resetScheduledEvents = () => {
	return (dispatch) => {
		dispatch({ type: LIST_SCHEDULED_EVENTS, payload: { success: false, data: [] } })

	}
}
export const listSwitchEvent = (id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/events-service/v1/events/parent/' + id).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LIST_SCHEDULED_EVENTS_SWITCH, payload: { success: true, data: data?.data } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}

export const rescheduleEvent = (requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/events-service/v1/events/reshedule', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: RESCHEDULE_SESSION, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: RESCHEDULE_SESSION_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}

export const rescheduleEventStatus = status => {
	return (dispatch) => {
		dispatch({ type: RESCHEDULE_SESSION, payload: status });
	}
}
export const rescheduleEventErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: RESCHEDULE_SESSION_ERROR, payload: status });
	}
}


export const reSchedulePaidEvent = (requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/events-service/v1/events/paidsession/reshedule', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: RESCHEDULE_PAID_SESSION, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const setReSchedulePaidEventStatus = status => {
	return (dispatch) => {
		dispatch({ type: RESCHEDULE_PAID_SESSION, payload: status });
	}
}
export const cancelEvent = (id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.put(BASE_URL + '/events-service/v1/events/cancel/' + id).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: CANCEL_SCHEDULE_SESSION, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: CANCEL_SCHEDULE_SESSION_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}

export const setCancelStatus = status => {
	return (dispatch) => {
		dispatch({ type: CANCEL_SCHEDULE_SESSION, payload: status });
	}
}

export const getScheduledSessionDetails = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/events-service/v1/events/bookingsessions').then(({ data }) => {
			const { headers: { statusCode, message }, recordInfo } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SCHEDULED_SESSION_DETAILS, payload: { status: true, data: recordInfo } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}


export const getConnectedSessionDetails = (id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/events-service/v1/events/bookingsessions/' + id).then(({ data }) => {
			const { headers: { statusCode, message }, recordInfo } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SCHEDULED_SESSION_DETAILS, payload: { status: true, data: recordInfo } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}

export const setScheduledSessionDetailsStatus = status => {
	return (dispatch) => {
		dispatch({ type: SCHEDULED_SESSION_DETAILS_STATUS, payload: status });
	}
}
export const getAdvisorSchedule = (id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/events-service/v1/events/bookingsessions/' + id).then(({ data }) => {
			const { headers: { statusCode, message }, recordInfo } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SCHEDULED_SESSION_DETAILS, payload: { status: true, data: recordInfo } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const getAdvisorScheduleStatus = status => {
	return (dispatch) => {
		dispatch({ type: SCHEDULED_SESSION_DETAILS_STATUS, payload: status });
	}
}
//advisor NETWORK recommendation

export const getAdvisorSuggested = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_ADVISOR_SUGGESTED_STATUS, payload: true });
				dispatch({ type: GET_ADVISOR_SUGGESTED, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};


export const getAdvisorSuggestedStatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_ADVISOR_SUGGESTED_STATUS, payload: status });
	}
}
export const resetSuggestedAdvisorData = () => {
	return (dispatch) => {
		dispatch({ type: GET_ADVISOR_SUGGESTED, payload: [] });
	}
}

export const getParentPlanDetails = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/network/plans/load/parent', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: PARENT_PLANS_DETAIL, payload: { data: data.data, status: true } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const getParentPlanStatus = status => {
	return (dispatch) => {
		dispatch({ type: PARENT_PLANS_INFO_STATUS, payload: status });
	}
}

export const CancelRequest = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/network/requests/cancel', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: CANCEL_REQUEST, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			dispatch({ type: ERROR_CANCEL_REQUEST, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const CancelRequestStatus = status => {
	return (dispatch) => {
		dispatch({ type: CANCEL_REQUEST, payload: status });
	}
}
export const ErrorCancelRequestStatus = status => {
	return (dispatch) => {
		dispatch({ type: ERROR_CANCEL_REQUEST, payload: status });
	}
}

export const AddAdvisorAcess = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/planaccess/advisors/provideaccess', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: ADD_ADVISOR_STATUS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			dispatch({ type: ADD_ADVISOR_STATUS_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const AddAdvisorAcessStatus = status => {
	return (dispatch) => {
		dispatch({ type: ADD_ADVISOR_STATUS, payload: status });
	}
}
export const AddAdvisorAcessErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: ADD_ADVISOR_STATUS_ERROR, payload: status });
	}
}

export const loadAdvisorReview = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/reviews/advisor/load', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				console.log(data.data, "data.datasssxsx")
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LOAD_ADVISOR_REVIEW, payload: { data: data.data } });
				dispatch({ type: LOAD_ADVISOR_REVIEW_STATUS, payload: { status: true } });
				dispatch({ type: TOTAL_ADVISOR_REVIEW, payload: { data: data } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const loadAdvisorReviewStatus = status => {
	return (dispatch) => {
		dispatch({ type: LOAD_ADVISOR_REVIEW_STATUS, payload: status });
	}
}

export const resetReview = () => {
	return (dispatch) => {
		dispatch({ type: RESET_REVIEW });
	}
}
export const FeedbackSubmit = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/reviews/advisor/add', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log("CancelRequest", data);
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: ADVISOR_FEADBACK_SUBMIT, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		}).catch(function (error) {
			// dispatch({ type: ERROR_CANCEL_REQUEST, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		});
	}
}

export const FeedbackEdit = (advisorReviewId, requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.put(BASE_URL + '/user-service/v1/reviews/advisor/edit/' + advisorReviewId, requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: ADVISOR_FEADBACK_EDIT, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		}).catch(function (error) {
			// dispatch({ type: ERROR_CANCEL_REQUEST, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		});
	}
}

export const EditFeedbackReset = status => {
	return (dispatch) => {
		dispatch({ type: ADVISOR_FEADBACK_EDIT, payload: status });
	}
}
export const FeedbackReset = status => {
	return (dispatch) => {
		dispatch({ type: ADVISOR_FEADBACK_SUBMIT, payload: status });
	}
}

export const FeedbackDelete = (advisorReviewId) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.delete(BASE_URL + '/user-service/v1/reviews/advisor/delete/' + advisorReviewId).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch(resetReview());
				dispatch({ type: ADVISOR_FEADBACK_DELETE, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		}).catch(function (error) {
			// dispatch({ type: ERROR_CANCEL_REQUEST, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		});
	}
}

export const FeedbackParentDelete = (advisorReviewId, studentId) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.delete(`${BASE_URL}/user-service/v1/reviews/advisor/delete/${advisorReviewId}${studentId ? `?studentId=${studentId}` : ''}`).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch(resetReview());
				dispatch({ type: ADVISOR_FEADBACK_DELETE, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		}).catch(function (error) {
			// dispatch({ type: ERROR_CANCEL_REQUEST, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
			}
		});
	}
}

export const DeleteFeedbackReset = status => {
	return (dispatch) => {
		dispatch({ type: ADVISOR_FEADBACK_DELETE, payload: status });
	}
}

export const hideConnectionRequest = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/network/hidefor', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: HIDE_CONNECTION_REQUEST, payload: true });
				dispatch(CancelRequestStatus(true));
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const setHideConnectionRequestStatus = status => {
	return (dispatch) => {
		dispatch({ type: HIDE_CONNECTION_REQUEST, payload: status });
	}
}

export const getFreeSessionStatus = (requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/events-service/v1/events/freesessioncompletedstatus', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SCHEDULED_FREESESSION_DETAILS_STATUS, payload: true })
				dispatch({ type: SCHEDULED_FREESESSION_DETAILS, payload: data?.data })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const getFreeSessionupdateStatus = status => {
	return (dispatch) => {
		dispatch({ type: SCHEDULED_FREESESSION_DETAILS_STATUS, payload: status });
	}
}

export const resetFreeSessionDetails = () => {
	return (dispatch) => {
		dispatch({ type: SCHEDULED_FREESESSION_DETAILS, payload: [] });
	}
}
export const revokeInvite = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/user-invite/revokeinvite', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: INVITATION_REVOKE, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: INVITATION_REVOKE_ERROR, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			dispatch({ type: INVITATION_REVOKE_ERROR, payload: true })
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		});
	}
}
export const revokeInviteStatus = status => {
	return (dispatch) => {
		dispatch({ type: INVITATION_REVOKE, payload: status });
	}
}
export const revokeInviteError = status => {
	return (dispatch) => {
		dispatch({ type: INVITATION_REVOKE_ERROR, payload: status });
	}
}

export const getFreeSessionRemaining = (studentId, id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/events-service/v1/events/freesessionremaining/' + studentId + '/' + id).then(({ data }) => {
			const { headers: { statusCode, message }, recordInfo } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: FREE_SESSION_REMAINING, payload: recordInfo })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const AddStudentAccess = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/planaccess/student/provideaccess', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: ADD_STUDENT_STATUS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			dispatch({ type: ADD_STUDENT_STATUS_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const AddParentAccess = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/parent/plans/provideaccess', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: ADD_STUDENT_STATUS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			dispatch({ type: ADD_STUDENT_STATUS_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const AddStudentAccessStatus = status => {
	return (dispatch) => {
		dispatch({ type: ADD_STUDENT_STATUS, payload: status });
	}
}
export const AddStudentAccessErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: ADD_STUDENT_STATUS_ERROR, payload: status });
	}
}
export const RemoveStudentAccess = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/planaccess/student/removeaccess', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: REMOVE_STUDENT_STATUS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: REMOVE_STUDENT_STATUS_ERROR, payload: true });
			}
		}).catch(function (error) {
			dispatch({ type: REMOVE_STUDENT_STATUS_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const RemoveStudentAccessStatus = status => {
	return (dispatch) => {
		dispatch({ type: REMOVE_STUDENT_STATUS, payload: status });
	}
}
export const RemoveStudentAccessErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: REMOVE_STUDENT_STATUS_ERROR, payload: status });
	}
}

export const exploreAdvisorForSpecific = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/byspecific', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LOAD_SPECIFIC_EXPLORE_ADVISOR_STATUS, payload: true });
				dispatch({ type: LOAD_SPECIFIC_EXPLORE_ADVISOR, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};


export const saveUserProfile = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/network/profiles/save', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SAVE_USER_PROFILE, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: ADVISOR_PROFILE_SAVE_ERROR, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			dispatch({ type: ADVISOR_PROFILE_SAVE_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		});
	}
}

export const advisorSaveStatus = status => {
	return (dispatch) => {
		dispatch({ type: SAVE_USER_PROFILE, payload: status });
	}
}
export const advisorSaveErrorStatus = status => {
	return (dispatch) => {
		dispatch({ type: ADVISOR_PROFILE_SAVE_ERROR, payload: status });
	}
}
export const unSaveUser = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/network/profiles/remove', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SAVE_USER_PROFILE, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: ADVISOR_PROFILE_SAVE_ERROR, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			dispatch({ type: ADVISOR_PROFILE_SAVE_ERROR, payload: true });
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		});
	}
}

export const getSavedUserProfiles = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/network/profiles/saved', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LOAD_SAVED_USER_PROFILE, payload: data.data, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const setSavedUserProfileStatus = status => {
	return (dispatch) => {
		dispatch({ type: LOAD_SAVED_USER_PROFILE_STATUS, payload: status });
	}
}

export const getExploreStudents = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/detailed', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_EXPLORE_STUDENT, payload: data?.data, status: true })
				dispatch({ type: GET_EXPLORE_STUDENT_STATUS, payload: true })

			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
			}
			console.log("Error****:", error.message);
		});

	}
}
export const getExploreStudentStatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_EXPLORE_STUDENT_STATUS, payload: status });
	}
};
export const advisorAvailablity = (advisorId, date) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(`${BASE_URL}/events-service/v1/events/advisoravailabilitycheck/${advisorId}/${date}`)
			.then(({ data }) => {
				const { headers: { statusCode, message } } = data
				if (statusCode === "200") {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: GET_ADVISOR_AVAILABILITY, payload: data?.data, status: true })
				} else {
					dispatch({ type: FETCH_ERROR, payload: message });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				}
			}).catch(function (error) {
				if (error.response) {
					const { headers: { message, statusCode }, errorlist, } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				} else {
					dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000)
				}
				console.log("Error****:", error.message);
			});

	}
}

// NetworkFriendRequest
export const sendRespondToParentRequest = (id, requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/network/parents/requests/respond/' + id, requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log(data);
				
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SEND_RESPOND_TO_PARENT_REQUEST, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			}
		} catch (error) {
			// dispatch({ type: USER_CONNECT_ERROR_STATUS, payload: true })
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		}
	};
};

export const respondToParentRequestStatus = status => {
	return (dispatch) => {
		dispatch({ type: SEND_RESPOND_TO_PARENT_REQUEST, payload: status });
	}
}

// Advisor Generic Search
export const genericAdvisor = (search, requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/genericsearchforadvisors/' + search, requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GENERIC_SEARCH_ADVISOR, payload: data.data });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000);
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		}
	};
};
export const resetGenericAdvisorData = data => {
	return (dispatch) => {
		dispatch({ type: GENERIC_SEARCH_ADVISOR, payload: data });
	}
}
export const genericSearchStudent = (search, requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/genericsearchforstudents/' + search, requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log(data, "dataaaa");
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GENERIC_SEARCH_STUDENT_STATUS, status: true });
				dispatch({ type: GENERIC_SEARCH_STUDENT, payload: data?.data });
				dispatch({ type: GET_EXPLORE_STUDENT_BY_SECTION, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000);
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		}
	};
};
export const setGenericSearchStudentStatus = status => {
	return (dispatch) => {
		dispatch({ type: GENERIC_SEARCH_STUDENT_STATUS, status: status });
	}
}
export const resetGenericStudentData = data => {
	return (dispatch) => {
		dispatch({ type: GENERIC_SEARCH_STUDENT, payload: data });
	}
}

export const unSubscription = (requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/subscription/unsubscribe', requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: UNSUBSCRIBE_ADVISOR, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000);
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		}
	};
};

export const packageUnsubscribeStatus = data => {
	return (dispatch) => {
		dispatch({ type: UNSUBSCRIBE_ADVISOR, payload: data });
	}
}

export const exploreAdvisorPagination = (requestParams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/byspecific', requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_ADVISOR_PAGINATION, payload: data });
				dispatch({ type: EXPLORE_ADVISOR_PAGINATION_STATUS, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 5000);
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		}
	};
};

export const getUserCount = (childId) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/user-service/v1/network/parents/overview/' + childId).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_USER_COUNT, payload: data })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				// setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				// setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
				// setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 9000);
			}
		});
	}
}
export const getNetworkUserCount = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/user-service/v1/network/overview').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_NETWORK_USER_COUNT, payload: data })
				dispatch({ type: GET_NETWORK_USER_COUNT_STATUS, status: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });

			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const archivePlan = (payload) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/plans/archive/' + payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_ARCHIVE_PLAN, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				dispatch({ type: SET_ARCHIVE_PLAN_ERROR, payload: true })
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: SET_ARCHIVE_PLAN_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const ArchiveStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_ARCHIVE_PLAN, payload: data });
	}
}
export const ArchivePlanErrorStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_ARCHIVE_PLAN_ERROR, payload: data });
	}
}

export const unArchivePlan = (payload) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/student-plans/v1/plans/unarchive/' + payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_UNARCHIVE_PLAN, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {

				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {

				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}

export const unArchiveStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_UNARCHIVE_PLAN, payload: data });
	}
}

export const archiveCourse = (payload, requestparams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.put(BASE_URL + '/student-plans/v1/courses/archive/' + payload, requestparams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_ARCHIVE_COURSE, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				dispatch({ type: SET_ARCHIVE_COURSE_ERROR, payload: true })
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: SET_ARCHIVE_COURSE_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const archiveCourseStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_ARCHIVE_COURSE, payload: data });
	}
}
export const archiveCourseErrorStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_ARCHIVE_COURSE_ERROR, payload: data });
	}
}
export const unArchiveCourse = (payload, requestparams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.put(BASE_URL + '/student-plans/v1/courses/unarchive/' + payload, requestparams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_UNARCHIVE_COURSE, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				dispatch({ type: SET_UNARCHIVE_COURSE_ERROR, payload: true })
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: SET_UNARCHIVE_COURSE_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const unArchiveCourseStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_UNARCHIVE_COURSE, payload: data });
	}
}
export const unArchiveCourseError = data => {
	return (dispatch) => {
		dispatch({ type: SET_UNARCHIVE_COURSE_ERROR, payload: data });
	}
}
export const archiveActivity = (payload, requestparams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.put(BASE_URL + '/student-plans/v1/activities/archive/' + payload, requestparams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_ARCHIVE_ACTIVITY, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				dispatch({ type: SET_ARCHIVE_ACTIVITY_ERROR, payload: true })
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: SET_ARCHIVE_ACTIVITY_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const archiveActivityStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_ARCHIVE_ACTIVITY, payload: data });
	}
}
export const archiveActivityErrorStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_ARCHIVE_ACTIVITY_ERROR, payload: data });
	}
}
export const unArchiveActivity = (payload, requestparams) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.put(BASE_URL + '/student-plans/v1/activities/unarchive/' + payload, requestparams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SET_UNARCHIVE_ACTIVITY, payload: true })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				dispatch({ type: SET_UNARCHIVE_ACTIVITY_ERROR, payload: true })
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: SET_UNARCHIVE_ACTIVITY_ERROR, payload: true })
				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}
export const unArchiveActivityStatus = data => {
	return (dispatch) => {
		dispatch({ type: SET_UNARCHIVE_ACTIVITY, payload: data });
	}
}
export const unArchiveActivityError = data => {
	return (dispatch) => {
		dispatch({ type: SET_UNARCHIVE_ACTIVITY_ERROR, payload: data });
	}
}

export const getExploreAdvisorCount = (id) => {
	console.log("working");
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.get(BASE_URL + '/user-service/v1/dashboard/parent/goals/counts/' + id).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_EXPLORE_ADVISOR_COUNT, payload: data });

			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
			console.log("Error****:", error.message);
		});
	}
};

export const viewDashboardCount = (payload) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/dashboard/isviewed', payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_UPDATES_VIEWED_COUNT, payload: true })

			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {

				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {

				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}

export const currentCoursesDashboard = (payload) => {
	console.log(payload,"payload");
	
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/dashboard/currentcourses', payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: CURRENT_COURSES_DASHBOARD, payload: data?.data })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {

				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {

				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}

export const currentActivitiesDashboard = (payload) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/dashboard/currentactivities', payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: CURRENT_ACTIVITY_DASHBOARD, payload: data?.data })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {

				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {

				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			}
		});
	}
}

export const inviteUserStudent = (payload) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/user-invite/student', payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				console.log("hihihihihihihi");
				
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_INVITE_STUDENT, payload: data })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)

			} else {
				console.log("hjuhjhjhjh");
				
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				dispatch({ type: USER_INVITE_STUDENT_ERROR, payload: true })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			console.log(error,"eeeeeeeeeeeeeeeeeeee");
			
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				dispatch({ type: USER_INVITE_STUDENT_ERROR, payload: true })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				const { headers: { message, statusCode }, errorlist } = error.response.data;

				dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
				// dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				// setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		});
	}
}

export const inviteUserAdvisor = (payload) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post(BASE_URL + '/user-service/v1/user-invite/advisor', payload).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			console.log(data, "actionData")
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: USER_INVITE_ADVISOR, payload: true })

			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
			console.log("Error****:", error.message);
		});
	}
}

export const inviteUserAdvisorStatus = data => {
	return (dispatch) => {
		dispatch({ type: USER_INVITE_ADVISOR, payload: data });
	}
}

export const inviteUserStudentStatus = data => {
	return (dispatch) => {
		dispatch({ type: USER_INVITE_STUDENT, payload: data });
	}
}

export const inviteStudentErrorStatus = data => {
	return (dispatch) => {
		dispatch({ type: USER_INVITE_STUDENT_ERROR, payload: data });
	}
}