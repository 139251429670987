import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Grid } from '@mui/material'
import { Controller, useForm } from "react-hook-form";
import { END_DATE, MILESTONE_TYPE, START_DATE } from "../../../Constants/commonLabels";
import { getMilestoneTypes, createMilestonforCourse, createMilestonforCourseStatus, GetMilestoneByActivity, GetMilestoneByCourse, updateCourseMilestone, createMilestonforActivityStatus, createMilestonforActivity, updateCourseMileStatus } from '../../../redux/actions'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select, { components } from "react-select";
import ResourceCard from "../../../componentsNew/Common/ResourceCard"
import "./addmilestone.less"
import ReactQuill from "react-quill";
import AddEditResource from "../AddEditResource"
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import moment from "moment";
import { useLocation, useParams } from "react-router";
import dayjs from "dayjs";
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const validation = Yup.object({
    mileStoneType: Yup.object().required("*Required").nullable(),
    milestoneTitle: Yup.string()
        .required("*Required")
        .max(20, "Milestone title should not exceed 20 characters")
        .min(2, "Milestone title should be minimum 2 characters")
        .matches(/^[a-zA-Z0-9\s]+$/, "Milestons title cannot accepts special characters")
        .nullable()
    ,
    description: Yup.string().transform(value => value?.replace(/<[^>]+>/g, '')?.replace(/\ +/g, ' '))?.max(750, "Description should not exceed 750 characters")?.nullable(),
    mileStoneStart: Yup.date()
        .required("Start date is required")
        .nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null))
        .max(moment().year(2100).endOf('day'), "Start date cannot exceed the year 2100"),
    mileStoneEnd: Yup.date()
        .required("End date is required")
        .nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null))
        .max(moment().year(2100).endOf('day'), "End date cannot exceed the year 2100")
        .test('is-after-start', "End date must be after the start date", function (value) {
            const { mileStoneStart } = this.parent;
            if (!mileStoneStart || !value) {
                // If either start or end date is not provided, don't perform the comparison
                return true;
            }
            return moment(value).isSameOrAfter(mileStoneStart, 'day');
        }),
})

const Index = (props) => {
    const { state } = useLocation();
    const params = useParams();

    const [resource, setResource] = useState([])
    const [resourcePopupShow, setResourcePopupShow] = useState(false)
    const [editData, setEditData] = useState(null)
    const [milestoneTypeOption, setMilestoneTypeOption] = useState([])
    const [errorList, setErrorList] = useState({})
    const [buttonClicked, setButtonClicked] = useState(false);
    const [minDate, setMinDate] = useState(dayjs());
    const [isSafari, setIsSafari] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const startRef = useRef(null);
    const endRef = useRef(null);

    const { register, handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors, } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });

    useEffect(() => {
        props.getMilestoneTypes();

        const userAgent = navigator.userAgent;

        // Check if the browser is Safari
        if (
            /Safari/.test(userAgent) &&
            /Apple Computer/.test(navigator.vendor)
        ) {
            setIsSafari(true);
        }
    }, [])

    useEffect(() => {
        setInitialValues()

        if (props.createCourseMilestoneStatus) {
            setButtonClicked(false)
        }
        if (props.mileCourseUpdata) {
            if (state?.activityData?.id) {
                let payload = {
                    "planId": state?.activityData?.planId,
                    "activityId": state?.activityData?.id
                }
                props?.GetMilestoneByActivity(payload)
            } else {
                let payload = {
                    "planId": params.id,
                    "courseId": params.courseId
                }
                props?.GetMilestoneByCourse(payload)
            }
            props.updateCourseMileStatus(false)
            props.handleClose()
            setButtonClicked(false)
        }
        if (props.createActivityMile) {
            setButtonClicked(false)
        }
        if (props?.userProfile?.timezone) {
            updateMinDate();
        }
    }, [props.milestoneTypes, props.createCourseMilestoneStatus,
    props?.createActivityMile, props.mileCourseUpdata, props.milestoneEditData, props?.userProfile])

    useEffect(() => {
        if (props.errorList) {
            setErrorList({ ...errorList })
            setButtonClicked(false)
        }
        if (props.milestoneEditData?.resources?.length > 0) {
            let temp = [];
            props.milestoneEditData?.resources?.map(data => {
                let payload = {
                    id: data.id,
                    name: data.name,
                    url: data.resourceURL
                }
                temp = [...temp, payload]
            })
            setResource([...temp])

        } else if (props.milestoneEditData?.resources?.length === 0) {
            setResource([])
        }

    }, [props.errorList, props.milestoneEditData, props?.isDeleteResource])

    const updateMinDate = () => {
        const now = dayjs();
        const tzDate = now.tz(props?.userProfile?.timezone).startOf('day');
        setMinDate(tzDate.format('YYYY-MM-DD'));
    };

    const setInitialValues = () => {
        const milestoneTypeOption = [];
        props.milestoneTypes?.map(milestoneType => {
            var temp = { label: milestoneType, value: milestoneType }
            milestoneTypeOption.push(temp)
        })

        if (props.milestoneEditData) {
            setValue("milestoneTitle", props.milestoneEditData.milestoneTitle)
            setValue("mileStoneStart", moment(props.milestoneEditData.startingDate).format("YYYY-MM-DD"))
            setValue("mileStoneEnd", moment(props.milestoneEditData.closingDate).format("YYYY-MM-DD"))
            setValue("description", props.milestoneEditData.otherDetails)
            filterObject("mileStoneType", milestoneTypeOption, props.milestoneEditData.milestoneType)
            setStartDate( moment(props.milestoneEditData.startingDate).format("MM-DD-YYYY")); 
            setEndDate(moment(props.milestoneEditData.closingDate).format("MM-DD-YYYY"));
        }

        setMilestoneTypeOption(milestoneTypeOption)
    }

    const closeResourcePopup = () => {
        setEditData(null)
        setResourcePopupShow(false)
    }

    const showResourcePopup = () => {
        setResourcePopupShow(true)
    }

    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.label?.toString() === value?.toString()
            );
        if (filtered[0]) {
            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const handleAddResource = (data) => {
        let resData = [...resource];
        if (Object.keys(data)?.includes('index')) {
            resData.splice(data.index, 1, { name: data?.name, url: data?.url });
        } else {
            resData.push({ name: data?.name, url: data?.url });
        }
        setResource(resData);
    };

    const handleEditResource = (data) => {
        setEditData(data)
        showResourcePopup()
    }

    const handleDeleteResource = (data) => {
        if (data?.index >= 0 && data?.index < resource.length) {
            const updatedResource = [...resource];
            updatedResource.splice(data?.index, 1);
            setResource(updatedResource);
        }
    }

    const onSubmit = (data) => {
        setButtonClicked(true)
        let payload = {}
        if (props.milestoneEditData) {
            payload = {
                planId: props.milestoneEditData.planId,
                milestoneId: props.milestoneEditData.id,
                milestoneType: data.mileStoneType?.value,
                milestoneTitle: data.milestoneTitle,
                startingDate: moment(data.mileStoneStart).format("YYYY-MM-DD"),
                closingDate: moment(data.mileStoneEnd).format("YYYY-MM-DD"),
                otherDetails: data.description
            }
            props.updateCourseMilestone(payload)
        } else if (state?.activityData?.id || props?.activityTab) {
            payload = {
                phaseId: state?.activityData?.phaseId,
                planId: state?.activityData?.planId,
                activityId: state?.activityData?.id,
                milestoneType: data.mileStoneType.value,
                milestoneTitle: data.milestoneTitle,
                closingDate: moment(data.mileStoneEnd).format("YYYY-MM-DD"),
                startingDate: moment(data.mileStoneStart).format("YYYY-MM-DD"),
                resources: [...resource],
                otherDetails: data.description,
                notes: []
            }
            console.log(payload, "payload");
            props.createMilestonforActivity(payload)
        } else {
            payload = {
                milestoneType: data.mileStoneType.value,
                milestoneTitle: data.milestoneTitle,
                phaseId: state?.courseData?.phaseId ? state?.courseData?.phaseId : state?.phaseData?.phaseId,
                planId: state?.courseData?.planId ? state?.courseData?.planId : state?.phaseData?.planId,
                courseId: state?.courseData?.id ? state?.courseData?.id : state?.phaseData?.id,
                closingDate: moment(data.mileStoneEnd).format("YYYY-MM-DD"),
                startingDate: moment(data.mileStoneStart).format("YYYY-MM-DD"),
                resources: [...resource],
                otherDetails: data.description,
                notes: []
            }
            props.createMilestonforCourse(payload)
        }
    }

    let descriptionWatch = watch("description");
    return (
        <>
            <Modal size={'md'} {...props} fullscreen={true} centered={true} dialogclassName={"mywork-popup"}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <form id="" onSubmit={handleSubmit(onSubmit)} >
                        <div className="addMilestone">
                            <div className="addMilestone__form">
                                <h1 className="addMilestone__form__title text-center">{Object.keys(props.milestoneEditData || [])?.length > 0 ? `Edit` : `Add`} Milestone</h1>
                                <Grid container mt={2}>
                                    <Grid item sm={12} md={12} lg={12} marginBottom={4} sx={{ width: '100%' }}>
                                        <Controller
                                            name="mileStoneType"
                                            control={control}
                                            render={({ field }) =>
                                                <Select name="mileStoneType" {...field} id="milestoneType"
                                                    placeholder={MILESTONE_TYPE} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                                    closeMenuOnSelect={true} isClearable={false} options={milestoneTypeOption}
                                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                    styles={{
                                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                    }}
                                                />
                                            }
                                        />
                                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.mileStoneType?.message}</div>
                                        {errorList?.mileStoneType && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.mileStoneType}</div>}
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                                        <div className="input-floating-label">
                                            <input refs="milestoneTitle" type="text" id="milestoneTitle" className="textbox--primary textbox--rounded input" autocomplete="off" maxLength={20}
                                                placeholder="Title" {...register('milestoneTitle')}
                                                onKeyPress={(event) => { if (! /^[A-Za-z0-9 ]+$/.test(event.key)) { event.preventDefault(); } }}
                                            />
                                            <label>Title</label>
                                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.milestoneTitle?.message}</div>
                                            {errorList?.milestoneTitle && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.milestoneTitle}</div>}
                                        </div>
                                    </Grid>
                                    <Grid container spacing={2} >

                                        {/* <div className="input-floating-label time">
                                                <input ref="mileStoneStart" id="mileStoneStart" type="date" className="textbox--primary textbox--rounded input" min={minDate}
                                                    name="mileStoneStart" placeholder={START_DATE} {...register('mileStoneStart')} 
                                                    style={{
                                                        paddingTop: isSafari ? '12px' : '0px', // Apply extra padding for Safari
                                                      }}
                                                />
                                                <label>{START_DATE}</label>
                                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.mileStoneStart?.message}</div>
                                                {errorList?.startingDate && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.startingDate}</div>}
                                            </div> */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Grid container spacing={2} >
                                                <Grid item md={6} lg={6} sx={{ width: '100%', paddingLeft : "33px !important",     borderRadius: "10px"}} className="input-floating-label">
                                                    <DatePicker
                                                        label="Start Date"
                                                        value={startDate} // Controlled state
                                                        onChange={(date) => {
                                                            setStartDate(date); // Update local state
                                                            setValue('mileStoneStart', date); // Update form state
                                                        }}
                                                        minDate={minDate}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                
                                                                error={!!errors?.mileStoneStart}
                                                                helperText={
                                                                    (errors?.mileStoneStart?.message || errorList?.startingDate) && (
                                                                        <span className="error-text p-0">
                                                                            {errors?.mileStoneStart?.message || errorList?.startingDate}
                                                                        </span>
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'off', // Disable autofill
                                                                    placeholder: 'Select start date',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item md={6} lg={6} sx={{ width: '100%' }} className="input-floating-label">
                                                    <DatePicker
                                                        label="End Date"
                                                        value={endDate} // Controlled state
                                                        onChange={(date) => {
                                                            setEndDate(date); // Update local state
                                                            setValue('mileStoneEnd', date); // Update form state
                                                        }}
                                                        minDate={minDate}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className="input-floating-label"
                                                                fullWidth
                                                                error={!!errors?.mileStoneEnd}
                                                                helperText={
                                                                    (errors?.mileStoneEnd?.message || errorList?.closingDate) && (
                                                                        <span className="error-text p-0">
                                                                            {errors?.mileStoneEnd?.message || errorList?.closingDate}
                                                                        </span>
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'off', // Disable autofill
                                                                    placeholder: 'Select end date',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </LocalizationProvider>

                                        

                                        {/* <Grid item md={6} lg={6} sx={{ width: '100%' }}>
                                            <div className="input-floating-label time">
                                                <input type="date" id="mileStoneEnd" className="textbox--primary textbox--rounded input" min={minDate}
                                                    name="mileStoneEnd" placeholder={END_DATE} {...register('mileStoneEnd')}
                                                    style={{
                                                        paddingTop: isSafari ? '12px' : '0px', // Apply extra padding for Safari
                                                    }}
                                                />
                                                {errorList?.closingDate && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.closingDate}</div>}
                                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.mileStoneEnd?.message}</div>
                                                <label>{END_DATE}</label>
                                            </div>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <div className="addMilestone__form__resource-sec mt-4" >
                                    <div className="addMilestone__form__resource-sec__title-sec">
                                        <p className="addMilestone__form__resource-sec__title-sec__title">Resource</p>
                                        <Button className="addMilestone__form__resource-sec__title-sec__addRsource-btn" id="addResource" onClick={() => showResourcePopup()}>Add Resource</Button>
                                    </div>
                                    <div className="addMilestone__form__resource-sec__resourses-cont" >
                                        {resource?.length > 0 ?
                                            resource.map((resource, index) => {
                                                return (
                                                    <ResourceCard
                                                        description={resource.name}
                                                        link={resource.url}
                                                        id={resource.id ? { resourceId: resource.id } : { index: index }}
                                                        editResource={handleEditResource}
                                                        mileStoneData={props.milestoneEditData}
                                                        resourceDelete={handleDeleteResource}
                                                    />
                                                )
                                            }) :
                                            <div className="addMilestone__form__resource-sec__resourses-cont__emptyState">
                                                <p className="text" style={{ textAlign: "center" }}>You do not have any resources for this milestones</p>
                                            </div>
                                        }
                                        {errorList?.resources && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.resources}</div>}
                                    </div>
                                </div>
                                <div className="addMilestone__form__resource-sec__title-sec">
                                    <p className="addMilestone__form__resource-sec__title-sec__title">Other details</p>
                                </div>
                                <div className="input-floating-label" style={{ marginBottom: "20px" }}>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) =>
                                            <ReactQuill className="textarea--primary textbox--rounded input" theme="snow" {...field} id="otherDetails"
                                                onKeyPress={(event) => { if (!/^[a-zA-Z0-9!@#\$%\^\ \&*\)\(+._-]+$/.test(event.key)) { event.preventDefault(); } }}
                                            />
                                        }
                                    />
                                    <div className="subtext m-s-auto"> <span>{descriptionWatch?.replace(/<[^>]+>/g, '')?.replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/750</div>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.description?.message}</div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="row m-0">
                                <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                                </div>
                                <div className="footer__center col-md-4 col-sm-4 col-4 text-center"></div>
                                <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                                    <Button className="footer__cta" disabled={buttonClicked} type="submit" id="saveMilestone" >{Object.keys(props.milestoneEditData || [])?.length > 0 ? `Update` : `Save`}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {resourcePopupShow &&
                <AddEditResource
                    show={resourcePopupShow}
                    onHide={() => closeResourcePopup()}
                    setData={(data) => handleAddResource(data)}
                    editData={editData || {}}
                    mileStoneData={props.milestoneEditData}
                />
            }
        </>
    );
};

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { milestoneTypes, createCourseMilestoneStatus, mileCourseUpdata, createActivityMile, isDeleteResource } = studentData;
    const { userProfile } = userProfileData;
    const { errorList } = commonData;
    return { errorList, milestoneTypes, createCourseMilestoneStatus, mileCourseUpdata, createActivityMile, userProfile, isDeleteResource };
};

export default connect(mapStateToProps, { getMilestoneTypes, createMilestonforActivity, GetMilestoneByActivity, GetMilestoneByCourse, createMilestonforActivityStatus, createMilestonforCourse, createMilestonforCourseStatus, updateCourseMilestone, updateCourseMileStatus })(withRouter(Index));